.header-logo {
  max-width: 255px;
}
.nav-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  padding: 1rem;
}
.nav-top-content {
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  background-color: #FD8400;
  padding: 0 1rem;
  border-top: 3px solid #002A5B;
}
.nav-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  list-style: none;
}
.nav-links li {
  color: #FFFFFF;
  padding: .5rem 2rem;
  transition: background-color 200ms ease-in;
}
.nav-links li:hover {
  background-color: #002A5B;
}
.nav-contact {
  display: flex;
}
.nav-contact-info {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  width: max-content;
}
.nav-contact-info a {
  text-decoration: none;
  color:#002A5B;
  transition: all 150ms ease-in;
}
.nav-contact-info a:hover {
  color:#FD8400;
}
.nav-contact-icon {
  margin-right: 1rem;
  font-size: 2rem;
}