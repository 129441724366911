.page-about {
  background: url(../../assets/page-about-bg.png) no-repeat fixed center;
  background-size: contain;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
}
.page-about h1 {
  color: #FD8400;
  font-weight: 400;
  font-size: 70px;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.page-about h1 span {
  font-weight: bold;
}
.about-section1 {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.about-section1 p {
  font-size: 20px;
  margin-right: 4rem;
  text-align: justify;
}
.about-section1 img {
  max-width: 600px;
  min-width: 400px;
  width: 100%;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 50%);
}
.member_img {
  max-width: 205px;
  width: 100%;
  margin-right: 2rem;
  border-radius: 50px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 50%);
}
.owner_card2 {
  border-top: 1px solid #002A5B;
  border-bottom: 1px solid #002A5B;
}
.owner_content {
  display: flex;
  align-items: center;
  margin: 2rem 20% 2rem 2rem;
  border-right: 5px solid #FD8400;
  padding: 2rem 0;
}
.owner_content2 {
  display: flex;
  align-items: center;
  margin: 2rem 2rem 2rem 20%;
  border-left: 5px solid #FD8400;
  padding: 2rem 0;
}
.owner_content2 img {
  margin-right: 0;
}
.owner_content2 .owner_text {
  margin-left: 2rem;
}
.owner_text p {
  text-align: justify;
}
.owner_text h3 {
  font-size: 30px;
  color: #414141;
}
.role {
  color: #FD8400;
}
.owner_text p {
  color: #717171;
  font-size: 20px;
}
.owner_text {
  margin-right: 2rem;
}

/* media queries */
@media screen and (max-width: 1100px){
  .owner_content {
      margin-right: 10%;        
  }
  .owner_content2 {
      margin-left: 10%;
  }    
}
@media screen and (max-width: 980px){
  h1 {
      margin: 0 auto;
      width: max-content;
  }
  .owner_content {
      flex-direction: column;
  }
  .about-section1 {
      flex-direction: column;
  }
  .about-section1 p {
      margin: 0 0 4rem;
  }
  .owner_content, .owner_content2 {
      margin-right: 0;
      margin-left: 0;
  }
  .owner_content2 {
      display: grid;
      grid-template-areas:
          "humbertoimg"
          "humbertotxt";
      justify-items: center;
  }
  .member_img {
      margin:  0 0 2rem;

  }
  .owner_content2 .owner_text {
      grid-area: humbertotxt;
  }
  .owner_content2 img {
      grid-area: humbertoimg;
  }
  .owner_text {
      text-align: center;
  }
  .owner_text h3 {
      margin-bottom: 1rem;
  }
  .owner_text p {
      margin-top: 1rem;
  }
}
@media screen and (max-width: 660px){
  .page-about {
    padding: 3rem 1.5rem;
  }
  .page-about h1 {
      margin: unset;
      width: unset;
  }
  .about-owners {
    border-top: 1px solid #002A5B;
    margin: 4rem 0;
  }
  .page-about h1 {
      font-size: 40px;
      text-align: center;
  }
  .owner_content {
    border-right: none;
  }
  .owner_content2 {
    border-left: none;
  }
  .owner_card2 {
    border-top: 5px solid #FD8400;
    border-bottom: 5px solid #FD8400;
  }
  .owner_text {
    margin-right: 0;
  }
  .owner_content2 .owner_text {
      margin-left: 0;
  }
}