* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #c2c2c2; 
} 
*::-webkit-scrollbar-thumb {
  background: #FD8400;
  border: 1px solid #002A5B;
}
*::-webkit-scrollbar-thumb:hover {
  background: #002A5B;
  border: 1px solid #FD8400;
}