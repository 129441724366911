.fornecedores-page {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0;
}
.fornecedores-page h4 {
  color: #414141;
  font-size: 20px;
  text-align: center;
}
.fornecedores-content {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  margin-top: 3rem;
}

@media screen and (max-width: 480px){
  .fornecedores-card {
    max-width: unset;
    margin: 1rem;
  }
  .fornecedores-page h4 {
    padding: 0 1rem;
  }
}